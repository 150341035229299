<div
  class="flex justify-end z-50 fixed right-0 top-16"
  *ngxPermissionsOnly="['seller', 'customer']"
>
  <div
    [title]="
      isWalletConnected
        ? ('main-page.tooltip.my-wallet' | translate)
        : ('actions.connect-wallet' | translate)
    "
    [ngClass]="spaceWalletClasses()"
    (click)="onWalletClick()"
    class="cursor-pointer mb-4 items-center justify-center flex gap-4 z-50 shadow-md right-0 rounded-l-full py-1 px-2 active:scale-y-90 duration-300 text-white"
    (mouseenter)="isWalletConnected && (isWalletHover = true)"
    (mouseleave)="isWalletHover = false"
    [@slideInOut]
  >
    <fa-icon
      [icon]="isWalletLoading ? faSpinner : faSpacechain"
      size="2x"
      class="p-2"
      [ngClass]="loadingClass(isWalletLoading)"
    ></fa-icon>
    <div class="items-center text-xs" *ngIf="isWalletHover" [@slideInOut]>
      <svg-icon
        src="assets/img/logos/spacechain-lettering.svg"
        svgClass="w-32"
      ></svg-icon>
      <h1 class="uppercase font-thin" aria-label="SpaceChain Wallet">Wallet</h1>
    </div>
  </div>
</div>

<app-dialog
  *ngIf="isWalletClicked"
  (cancel)="onDialogCancel()"
  size="sm"
>
  <div class="flex flex-col gap-3">
    <div
      class="flex flex-col gap-3"
      *ngIf="isWalletConnected; else noWalletConnected"
    >
      <h1
        class="place-self-end text-secondary-300 text-xs"
        [title]="'main-page.tooltip.our-address' | translate"
      >
        {{ walletAddress }}
      </h1>
      <h1 class="text-secondary-500">
        <span class="font-bold">{{ "labels.address" | translate }}</span>
        {{ spaceWallet?.address }}
      </h1>
      <h1 class="text-primary-500">
        <span class="font-bold">{{ "labels.balance" | translate }}</span>
        <span [title]="spaceWallet?.balance" class="lowercase">
          {{ spaceWallet?.estBalance }} {{ "labels.credits" | translate }}
        </span>
      </h1>

      <form
        (ngSubmit)="onEthereumPay()"
        [formGroup]="dataForm"
        class="flex flex-col gap-3 bg-gray-100 border border-gray-300 p-3 rounded-md"
      >
        <app-input
          [label]="'input-labels.pay-ethereum' | translate"
          id="amountInEUR"
          formControlName="amountInEUR"
          [placeholder]="'placeholders.amount' | translate"
          inputType="number"
          inputStep="5"
          [required]="true"
        ></app-input>
        <app-button
          [iconClasses]="loadingClass(isSendFundsLoading)"
          class="w-full"
          [leftIcon]="isSendFundsLoading ? faSpinner : faEthereum"
          [isWhite]="true"
          [isFull]="true"
          [label]="'actions.buy-more' | translate"
          type="submit"
          [isDisabled]="!dataForm.valid || isSendFundsLoading"
        ></app-button>
      </form>
    </div>
    <ng-template #noWalletConnected>
      <app-button
        *ngIf="isWalletLoading; else walletLoaded"
        [isWhite]="true"
        [isFull]="true"
        [leftIcon]="faSpinner"
        iconClasses="animate-spin"
        [label]="'actions.loading.searching-connected-wallets' | translate"
      >
      </app-button>
      <ng-template #walletLoaded>
        <h1>{{ "helper-texts.no-wallet-connected" | translate }}</h1>
        <a
          class="hover:cursor-pointer hover:text-primary-500 duration-300 hover:underline font-bold text-secondary-500"
        >
          <span (click)="onConnectWalletClick()">{{
            "actions.connect-wallet" | translate
          }}</span>
        </a>
      </ng-template>
    </ng-template>
  </div>
</app-dialog>
