import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import {
  CurrencyCode,
  ethereumPaymentFormConfig,
  SnackbarService,
  SpaceChainService
} from '@core';
import { WALLET_ADDRESS } from '@core/configs/spacechain.config';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { faSpinner, faWallet } from '@fortawesome/free-solid-svg-icons';
import { currencyToEth } from 'shared/helpers/crypto';
import { faSpacechain } from '../../../../../assets/icons';

@Component({
  selector: 'spacechain-wallet',
  templateUrl: './spacechain-wallet.component.html',
  styleUrls: ['./spacechain-wallet.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class SpacechainWalletComponent implements OnInit {
  faSpacechain = faSpacechain;
  faSpinner = faSpinner;
  faEthereum = faEthereum;
  faWallet = faWallet;

  isWalletHover: boolean = false;
  isWalletClicked: boolean = false;
  isWalletConnected: boolean = false;
  isWalletLoading: boolean = false;
  isSendFundsLoading: boolean = false;

  spaceWallet = this.spaceChainService.wallet;
  dataForm = this.formBuilder.group(ethereumPaymentFormConfig);

  walletAddress = WALLET_ADDRESS;

  constructor(
    private spaceChainService: SpaceChainService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    this.spaceChainService.$change.subscribe(({ isEmpty }) => {
      if (isEmpty) {
        this.isWalletConnected = false;
      }
    });

    this.spaceChainService.$wallet.subscribe((wallet) => {
      this.spaceWallet = wallet;
      if (!this.spaceWallet) this.isWalletConnected = false;
    });

    if (this.spaceChainService.isConnected) this.onConnectWalletClick();
  }

  async onWalletClick() {
    this.isWalletClicked = true;
    await this.spaceChainService.hasAddress().then(async (hasAddress) => {
      if (hasAddress) {
        this.onConnectWalletClick();
      }
    });
  }

  async onConnectWalletClick() {
    if (!this.isWalletConnected) {
      this.isWalletLoading = true;
      this.spaceChainService
        .getAccount()
        .then((data) => {
          this.isWalletConnected = true;
          this.spaceWallet = data;
        })
        .catch(() => (this.isWalletConnected = false))
        .finally(() => (this.isWalletLoading = false));
    }
  }

  async onEthereumPay() {
    if (this.dataForm.value.amountInEUR) {
      currencyToEth(this.dataForm.value.amountInEUR, CurrencyCode.EUR).then(
        ({ bnAmount }) => {
          this.isSendFundsLoading = true;
          this.spaceChainService
            .sendFunds(bnAmount)
            .then((transaction) =>
              this.spaceChainService
                .deposit(transaction.hash)
                .then(() =>
                  this.snackbarService
                    .info(
                      'Info',
                      'Transaction received but still pending, please wait.'
                    )
                    .during(5000)
                    .show()
                )
                .finally(() => (this.isSendFundsLoading = false))
            )
            .catch((err) =>
              this.snackbarService
                .danger('Oops!', err.message)
                .during(5000)
                .show()
            )
            .finally(() => (this.isSendFundsLoading = false));
        }
      );
    }
  }

  onDialogCancel() {
    this.isWalletClicked = !this.isWalletClicked
    this.dataForm.patchValue({ amountInEUR: null })
  }

  loadingClass(loading: boolean) {
    return { 'animate-spin': loading };
  }

  spaceWalletClasses() {
    return this.isWalletConnected ? 'bg-secondary-500' : 'bg-gray-500';
  }
}
