import { EventEmitter, Injectable } from '@angular/core';
import {
  Account,
  Balance,
  EthereumEventChange,
  GasPricesResponse,
  WalletState,
  Withdraw
} from '@core/models';
import { BigNumber, providers } from 'ethers';

import {
  EIP155,
  fetchOptions,
  GAS_PRICE_URL,
  SPACECHAIN_WALLET_URL,
  WALLET_ADDRESS
} from '@core/configs/spacechain.config';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { Subject } from 'rxjs';
import { LocalstorageService } from '../localstorage/localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class SpaceChainService {
  $change = new EventEmitter<EthereumEventChange>();

  walletState = localStorage.getItem('__wallet_state__');

  wallet?: Account;
  $wallet = new Subject<Account | undefined>();

  isConnected = this.walletState === WalletState.CONNECTED;

  constructor(private localStorageService: LocalstorageService) {
    window.ethereum?.on('accountsChanged', async (payload: string[]) => {
      const isEmpty = payload.every((p) => !p);

      if (isEmpty) {
        this.setWalletState(WalletState.NOT_CONNECTED);
        this.wallet = undefined;
        this.$wallet.next(this.wallet);
      } else this.setWalletState(WalletState.CONNECTED);

      this.$change.emit({ payload, isEmpty });
    });

    this.localStorageService
      .$watchStorage()
      .subscribe(
        ({ data }) => (this.isConnected = data === WalletState.CONNECTED)
      );
  }

  setWallet(
    balance: string,
    address = (this.wallet as Account).address,
    signer = (this.wallet as Account).signer
  ) {
    const estBalance = parseFloat(balance).toPrecision(4);

    this.wallet = { balance, estBalance, address, signer };

    this.$wallet.next(this.wallet);
  }

  setWalletState(walletState: WalletState) {
    this.localStorageService.setItem('__wallet_state__', walletState);

    this.walletState = localStorage.getItem('__wallet_state__');
  }

  async getCurrentGasPrices() {
    const response: GasPricesResponse = await fetch(GAS_PRICE_URL).then(
      (response) => response.json()
    );

    return {
      low: response.safeLow / 10,
      medium: response.average / 10,
      high: response.fast / 10,
    };
  }

  async getBalance(address: string): Promise<Balance> {
    const balance = await fetch(
      `${SPACECHAIN_WALLET_URL}/balance?address=${address}`,
      fetchOptions
    );

    return balance.json();
  }

  async getAccount(): Promise<Account> {
    return window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(async () => {
        const provider = new providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();

        this.setWalletState(WalletState.CONNECTED);

        return this.getBalance(address)
          .then((response) => {
            const { balance } = response;

            const estBalance = parseFloat(balance).toPrecision(4);

            this.wallet = { address, signer, balance, estBalance };

            return this.wallet;
          })
          .catch(() => {
            this.wallet = { signer, address };

            return this.wallet;
          });
      })
      .catch((err) => {
        this.setWalletState(WalletState.NOT_CONNECTED);
        throw new ErrorEvent(err);
      });
  }

  async generateTxReference() {
    const txReference = await fetch(`${SPACECHAIN_WALLET_URL}/new_tx_id`, fetchOptions);

    const response = txReference.json();

    return response;
  }

  async withdraw(payload: Withdraw) {
    const withdraw = await fetch(`${SPACECHAIN_WALLET_URL}/withdraw`, {
      ...fetchOptions,
      method: 'POST',
      body: JSON.stringify(payload),
    });

    const response = withdraw.json();

    response.then(({ balance }) => this.setWallet(balance));

    return response;
  }

  async deposit(txHash: string) {
    const balance = await fetch(`${SPACECHAIN_WALLET_URL}/deposit`, {
      ...fetchOptions,
      method: 'POST',
      body: JSON.stringify({ txHash }),
    });

    const response = balance.json();

    return response;
  }

  async hasAddress() {
    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    return signer
      .getAddress()
      .then(() => true)
      .catch(() => false);
  }

  async sendFunds(amount: BigNumber): Promise<TransactionResponse> {
    return new Promise(async (resolve, reject) => {
      this.getAccount().then(async (account) => {
        if (account.balance) {
          const provider = new providers.Web3Provider(window.ethereum);

          const nonce = provider.getTransactionCount(account.address);

          const transaction: providers.TransactionRequest = {
            to: WALLET_ADDRESS,
            value: amount,
            nonce: await nonce,
            chainId: EIP155.GOERLI,
          };

          return provider
            .getSigner()
            .sendTransaction(transaction)
            .then((response) => resolve(response))
            .catch((error) =>
              reject({
                message: error.reason,
                code: error.code,
              })
            );
        } else
          reject({
            message: 'No funds for specified wallet.',
            code: 'EMPTY_WALLET',
          });
      });
    });
  }

  async signMessage(amount: number): Promise<{ balance: number; tx_id: string }> {
    return new Promise(async (resolve, reject) => {
      this.getAccount().then(async (account) => {
        if (account.balance) {
          const provider = new providers.Web3Provider(window.ethereum);

          const message = `Withdrawl approval of ${amount} Credits from my personal SpaceChain Wallet`;

          const signature = provider
            .getSigner()
            .signMessage(message)
            .then((response) => response)
            .catch(({ error }) =>
              reject({
                message: error.message,
                code: error.code,
              })
            );

          const withdraw: Withdraw = {
            msg: message,
            signature: await signature,
            address: account.address,
            amount,
          };

          return this.withdraw(withdraw)
            .then((response) => resolve(response))
            .catch(({ error }) =>
              reject({
                message: error.message,
                code: error.code,
              })
            );
        } else
          reject({
            message: 'No funds for specified wallet.',
            code: 'EMPTY_WALLET',
          });
      });
    });
  }
}
