import { ICMSCollectionBecomeSeller, ICMSServiceNotAvailable, ICMSSmsLayerInformation } from './../../models/cms/collections/home';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments';
import {
    CMSResponse,
    IServiceEvaluation,
    ICMSCollectionAboutSection, ICMSCollectionGallery,
    ICMSCollectionNxtGeoservices, ICMSCollectionNxtSocial, ICMSCollectionNxtUserReview,
    ICMSCollectionWelcomeSection, ICMSNesAndEventsSection,
    IServiceEvaluationPayload
} from '../../models';
import {map, Observable, shareReplay, switchMap} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class CmsService {
    lang!: string;
    private apiURL = environment.cms.api;
    private website = environment.cms.website;

    constructor(
        private httpService: HttpClient,
        private oidcSecurityService: OidcSecurityService
    ) {
    }

    public getWelcomeSection(): Observable<CMSResponse<ICMSCollectionWelcomeSection>> {
        return this.httpService
            .get<CMSResponse<ICMSCollectionWelcomeSection>>(
                `${this.apiURL}/${this.website}-home-welcome-section?[populate]=img,leftButton,rightButton,twitter,linkedin,youtube`
            )
    }

    public getAboutSection(): Observable<CMSResponse<ICMSCollectionAboutSection>> {
        return this.httpService
            .get<CMSResponse<ICMSCollectionAboutSection>>(
                `${this.apiURL}/${this.website}-home-about-section?populate=keyPoints.icon`
            )
    }

    public getGallerySection(): Observable<CMSResponse<ICMSCollectionGallery>> {
        return this.httpService
            .get<CMSResponse<ICMSCollectionGallery>>(
                `${this.apiURL}/${this.website}-home-gallery-section?populate=gallery.img,video`
            )
    }

    public getGeoserviceSection(): Observable<CMSResponse<ICMSCollectionNxtGeoservices>> {
        return this.httpService
            .get<CMSResponse<ICMSCollectionNxtGeoservices>>(
                `${this.apiURL}/${this.website}-home-geoservice-section?populate=geoservices.icon,geoservices.items,partners_logo`
            )
    }

    public getUserReviewSection(): Observable<CMSResponse<ICMSCollectionNxtUserReview>> {
        return this.httpService
            .get<CMSResponse<ICMSCollectionNxtUserReview>>(
                `${this.apiURL}/${this.website}-home-user-review-section?populate=userReviews.userAvatar`
            )
    }

    public getSocialSection(): Observable<CMSResponse<ICMSCollectionNxtSocial>> {
        return this.httpService
            .get<CMSResponse<ICMSCollectionNxtSocial>>(
                `${this.apiURL}/${this.website}-home-social-section?populate=socialNetworks.icon`
            )
    }

    public getNewsAndEventsSection(): Observable<CMSResponse<ICMSNesAndEventsSection>> {
        return this.httpService
            .get<CMSResponse<ICMSNesAndEventsSection>>(
                `${this.apiURL}/nxt-news-and-event?populate=newsEventsCard.image`
            )
    }

    public getServiceEvaluations(serviceId: String): Observable<CMSResponse<IServiceEvaluation[]>> {
      return this.httpService
        .get<CMSResponse<IServiceEvaluation[]>>(
          `${this.apiURL}/service-evaluations?filters[websiteSlug][$eq]=${this.website}&filters[serviceId][$eq]=${serviceId}&sort[0]=rating:desc&sort[1]=createdAt:desc`
        ).pipe(shareReplay(1), map((response) => response))
    }

    public storeServiceEvaluation(data: IServiceEvaluationPayload ): Observable<IServiceEvaluation> {
      return this.oidcSecurityService.getUserData().pipe(switchMap((userData) => {
      
      const payload = {
        data: {
          ...data,
          userName: userData.displayName,
          websiteSlug: this.website,
          inum: userData.inum
        }
      };
      return this.httpService
      .post<CMSResponse<IServiceEvaluation>>(`${this.apiURL}/service-evaluations`, payload)
      .pipe(map((response) => response.data))
      
    }

      ));   
}

    public getBecomeSeller(): Observable<CMSResponse<ICMSCollectionBecomeSeller>> {
        return this.httpService
          .get<CMSResponse<ICMSCollectionBecomeSeller>>(
            `${this.apiURL}/become-seller`
          ).pipe(shareReplay(1), map((response) => response))
      }

    public getServiceNotAvailable(): Observable<CMSResponse<ICMSServiceNotAvailable>>{
        return this.httpService
          .get<CMSResponse<ICMSServiceNotAvailable>>(
            `${this.apiURL}/${this.website}-service-not-available?populate=Message`
          )
    }

    public getWmsLayerInformation(): Observable<CMSResponse<ICMSSmsLayerInformation>>{
        return this.httpService
            .get<CMSResponse<ICMSSmsLayerInformation>>(
                `${this.apiURL}/${this.website}-wms-layer-information?populate=wmsLayers`
            );
    }
}
