import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

interface Storage {
  status: string;
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  constructor() {}

  private storage = new Subject<Storage>();

  $watchStorage(): Observable<Storage> {
    return this.storage.asObservable();
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.storage.next({ status: 'added', data });
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
    this.storage.next({ status: 'removed' });
  }
}
