import { AnalyticsService, ICMSServiceNotAvailable, SnackbarService } from '@core';
import { BecomeSellerService } from './../../../../@core/services/become-seller/become-seller.service';
import { CMSResponse } from './../../../../@core/models/cms/components/reponse';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  CmsService,
  ICMSCollectionBecomeSeller,
  LangId,
  TranslateService,
} from '@core';
import {
  faSignInAlt,
  faSignOutAlt,
  faTimes,
  faUserTag,
} from '@fortawesome/free-solid-svg-icons';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, take } from 'rxjs';
import * as pkg from '../../../../../../package.json';
import { faSpacechain } from '../../../../../assets/icons';
import { environment } from '../../../../../environments';

@Component({
  selector: 'app-navbar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
    trigger('mobileMenu', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)' })),
      ]),
    ]),
  ],
})
export class NavBarComponent implements OnInit, AfterViewInit {
  @Input() isCollapsed = true;
  @Input() scrollTop = 0;
  /**
   * @require
   */
  @Input() logo!: string;
  @Output() currentLang = new EventEmitter<LangId>();

  userRole: string = 'none';
  isAuthenticated: boolean = false;
  isShowingProfileCard: boolean = false;
  signOutIcon = faSignOutAlt;
  faSpacechain = faSpacechain;
  faUserTag = faUserTag;
  close = faTimes;
  signIn = faSignInAlt;
  isMenuOpen: boolean = false;
  isMobile: boolean = false;
  isBecomeASellerClicked: boolean = false;
  env = environment;
  pkg = pkg;
  becomeSeller$!: Observable<CMSResponse<ICMSCollectionBecomeSeller>>;
  serviceNotAvailable: ICMSServiceNotAvailable;


  constructor(
    public oidcSecurityService: OidcSecurityService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private translateService: TranslateService,
    private cmsService: CmsService,
    private becomeSellerService: BecomeSellerService,
    private snackbarService: SnackbarService,
    private analyticsService: AnalyticsService
  ) {}

  isEnglish: boolean = this.translateService.currentLanguage === 'en';

  ngOnInit(): void {
    this.oidcSecurityService.isAuthenticated$.subscribe(
      ({ isAuthenticated }) => {
        this.isAuthenticated = isAuthenticated;
      }
    );

    this.becomeSeller$ = this.cmsService.getBecomeSeller();

    this.cmsService.getServiceNotAvailable().pipe(take(1)).subscribe({
      next: (payload) =>{

        this.serviceNotAvailable = payload.data;

      }, error: (erro)=>{
        
      }
    });      
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = event.target.window.innerWidth < 768;
  }

  ngAfterViewInit(): void {
    this.isMobile = window.innerWidth < 768;
  }

  setLang() {
    this.isEnglish = !this.isEnglish;
    const langId: LangId = this.isEnglish ? 'en' : 'pt';

    this.currentLang.emit(langId);
  }

  login(): void {
    // this.analyticsService.emitButtonClickedEvent('navbar_login', 'login');
    this.oidcSecurityService.authorize();
  }

  signOut(): void {
    this.isShowingProfileCard = false;
    // this.analyticsService.emitButtonClickedEvent('navbar_logout', 'logout');
    this.oidcSecurityService.logoff().pipe(take(1)).subscribe(() => {
      this.permissionsService.flushPermissions();
      this.router.navigate(['/sign-out']).finally();
    });
    
  }

  getInitials(name: string): string {
    if (!name) {
      return 'U';
    }
    const initials = name.split(' ');
    if (initials.length >= 2) {
      return (
        initials[0].charAt(0).toUpperCase() +
        initials[1].charAt(0).toUpperCase()
      );
    } else {
      return initials[0].charAt(0).toUpperCase();
    }
  }

  languageClass(fontWeightBefore: string, fontWeightAfter: string) {
    return this.isEnglish ? fontWeightBefore : fontWeightAfter;
  }

  onToggleMobileMenu() {}

  onBecomeASellerClick() {
    this.isBecomeASellerClicked = true;
  }

  requestBecomeSeller() {
    this.becomeSellerService.requestBecomeSeller().subscribe({
      next: () => {
        this.isBecomeASellerClicked = false;

        this.translateService
          .get([
            'labels.become-a-seller-title-success',
            'labels.become-a-seller-message-success',
          ])
          .subscribe((data) =>
            this.snackbarService
              .success(
                data['labels.become-a-seller-title-success'],
                data['labels.become-a-seller-message-success']
              )
              .during(5000)
              .show()
          );
      },
    });
  }
}
