<h1 class="text-primary-600 font-bold">
  {{ "main-page.services.thematic-areas" | translate }}
</h1>
<div class="mt-2" *ngIf="!isLoading; else loading">
  <ng-container *ngFor="let domainArea of domainAreas; index as idx">
    <div
      class="flex flex-row justify-between items-center p-2 cursor-pointer"
      (click)="onExpandOrCollapseRow(idx)"
    >
      <h5 class="text-secondary-500 font-medium">{{ domainArea.name }}</h5>

      <fa-icon
        *ngIf="!domainArea.expanded"
        [icon]="iconUp"
        class="text-gray-500"
      ></fa-icon>
      <fa-icon
        *ngIf="domainArea.expanded"
        [icon]="iconDown"
        class="text-gray-500"
      ></fa-icon>
    </div>

    <div *ngIf="domainArea.expanded" class="p-2">
      <ng-container
        *ngFor="let category of domainArea.categories; index as idx"
      >
        <app-checkbox-input
          (propagate)="onSelectCategory($event)"
          [label]="category.name"
          [value]="category.name"
          [isChecked]="selectedCategories.includes(category.name)"
        ></app-checkbox-input>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="flex flex-col gap-2 col-span-2 min-h-full p-4 mt-2 rounded-md">
    <div class="bg-gray-200 animate-pulse h-fit p-4 rounded-lg"></div>
    <div class="bg-gray-200 animate-pulse h-fit p-3 rounded-lg w-3/4"></div>
    <div class="bg-gray-200 animate-pulse h-fit p-3 rounded-lg w-2/4"></div>
    <div class="bg-gray-200 animate-pulse h-fit p-3 rounded-lg w-1/4"></div>
  </div>
</ng-template>
