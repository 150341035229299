<ng-container *ngIf="!isMobile; else mobileLink">
 <ng-container *ngIf="link === '#'; else outSideLink">
   <a
     [routerLinkActiveOptions]="{ exact: true }"
     [routerLink]="route"
     class="text-sm font-medium no-underline text-secondary-500 uppercase hover:text-primary-500 duration-300"
     routerLinkActive="!text-primary-500"
   >
     {{ label }}
   </a>
 </ng-container>
  <ng-template #outSideLink>
   <a
     class="text-sm font-medium no-underline text-secondary-400 uppercase hover:text-primary-500 duration-300"
     [href]="link"
     target="_blank"
   >
     {{ label }}
   </a>
 </ng-template>
</ng-container>
<ng-template #mobileLink>
  <a
    [routerLinkActiveOptions]="{ exact: true }"
    [routerLink]="route"
    class="
      block
      px-2
      py-4
      text-base
      font-medium
      no-underline
      text-secondary-500
      uppercase
      duration-500
      hover:px-4
      hover:text-primary-500
    "
    routerLinkActive="!bg-gradient-to-r from-primary-500 to-primary-600 !text-white"
  >
    {{ label }}
  </a>
</ng-template>
