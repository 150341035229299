<!--TODO *ngIf has to be analyse in the future-->
<div		
	*ngIf="isSnackbarActive && snackbarService.message !== 'Service does not exist.'"
		class="
    snackbar
    {{ snackbarService.snackbarConfig.snackbar.bgColor }}
    {{ snackbarService.snackbarConfig.snackbar.textColor }}
    rounded-lg
    px-4
    py-3
    shadow-md
  "
		role="alert"
>

	<div class="flex">
		<div class="py-1 w-12">
			<fa-icon
					[icon]="snackbarService.snackbarConfig.snackbar.icon"
					class="fill-current mr-4 animate-ping absolute opacity-75"
					size="2x"
			></fa-icon>
			<fa-icon
					[icon]="snackbarService.snackbarConfig.snackbar.icon"
					class="fill-current mr-4 absolute"
					size="2x"
			></fa-icon>
		</div>
		<div>

			<div  class="grid grid-cols-2">
				 <p class="font-bold">{{ snackbarService.title }}</p>

				 <button  *ngIf="snackbarService.flag === true" class="justify-self-end" (click)="closeSnackbar()">
					<fa-icon [icon]="faClose" size="lg"></fa-icon>
			   	</button>
			</div>	

		
			<p class="text-sm">{{ snackbarService.message }}</p>
				
			<div *ngIf="snackbarService.flag === true" class="w-full text-left">
				<p class="text-sm">{{'alerts.description.area-is-not-available.instructions' | translate}}</p>
				<li class="text-sm">{{'alerts.description.area-is-not-available.information-required.toi' | translate}}</li>
				<li class="text-sm">{{'alerts.description.area-is-not-available.information-required.aoi' | translate}}</li>
				<li class="text-sm">{{'alerts.description.area-is-not-available.information-required.other' | translate}}</li>
				<a [href]="snackbarService.url"
				target="_blank" 
				class="underline">{{'alerts.description.area-is-not-available.button' | translate}}</a>
			</div>
		</div>
	</div>
</div>

