<footer class="py-6 px-12 bg-secondary-500 text-white sm:p-6">
  <div class="md:flex md:justify-between px-6">
    <div class="mb-6 md:mb-0">
      <div class="">
        <h2 class="mb-6 text-sm font-semibold uppercase text-primary-500">
          SUPPORTED BY
        </h2>
        <div class="flex flex-col items-start gap-2 w-1/2 ml-6">
            <a href="https://elecnor-deimos.com/"><img alt="EU flag" class="h-10" src="assets/img/logos/deimos.svg"/></a>
            <a href="https://www.esa.int/"><img alt="EU flag" class="h-9" src="assets/img/logos/ESA.svg"/></a>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
      <div>
        <h2 class="mb-6 text-sm font-semibold uppercase text-primary-500">
          Resources
        </h2>
        <ul class="text-white">
          <li class="mb-4">
            <a
              href="https://service4eo.atlassian.net/servicedesk"
              class="hover:underline"
              >Support</a
            >
          </li>
        </ul>
      </div>
      <div>
        <h2 class="mb-6 text-sm font-semibold text-primary-500 uppercase">
          Follow us
        </h2>
        <ul class="text-white">
          <li class="mb-4">
            <a href="#" class="hover:underline">Twitter</a>
          </li>
          <li class="mb-4">
            <a href="#" class="hover:underline">Linkedin</a>
          </li>
          <li>
            <a href="#" class="hover:underline">Youtube</a>
          </li>
        </ul>
      </div>
      <div>
        <h2 class="mb-6 text-sm font-semibold text-primary-500 uppercase">
          Legal
        </h2>
        <ul class="text-white">
          <li class="mb-4">
            <a
              [routerLink]="'/home/legal-notice'"
              target="_blank"
              *ngIf="isLoggedIn"
              class="hover:underline"
              >{{ "footer.legal-notice" | translate }}</a
            >
          </li>
          <li class="mb-4">
            <a [routerLink]="'/home/cookie-policy'" class="hover:underline" target="_blank">{{
              "footer.cookie-policy" | translate
            }}</a>
          </li>
          <li class="mb-4">
            <a [routerLink]="'/home/privacy-policy'" class="hover:underline" target="_blank">{{
              "footer.privacy-policy" | translate
            }}</a>
          </li>
          <li>
            <a href="#" class="hover:underline">{{ 'footer.terms-conditions' | translate }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
  <div class="sm:flex sm:items-center sm:justify-between px-6">
    <span class="text-sm text-white sm:text-center">
      © {{ year }} {{ pkg.projetName }}. All Rights Reserved.
    </span>
    <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
      <a
        [href]="env.externalLinks.twitter"
        class="text-white hover:text-primary-500"
      >
        <fa-icon [icon]="faTwitter" size="2x"></fa-icon>
      </a>
      <a
        [href]="env.externalLinks.linkedin"
        class="text-white hover:text-primary-500"
      >
        <fa-icon [icon]="faLinkedinIn" size="2x"></fa-icon>
      </a>
      <div *ngIf = "env.externalLinks.youtube !== '' ">
      <a
        [href]="env.externalLinks.youtube"
        class="text-white hover:text-primary-500"
      >
        <fa-icon [icon]="faYoutube" size="2x"></fa-icon>
      </a>
      </div>
    </div>
  </div>
</footer>
<div
  class="relative flex w-full px-4 bg-secondary-700 justify-between text-secondary-300 text-xs"
>
  <div>
    <span
      >Powered by
      <a href="https://store.services4eo.com/" target="_blank" class="font-bold"
        >store4EO</a
      ></span
    >
  </div>
  <div>
    <span
      ><a href="https://elecnor-deimos.com/" target="_blank"
        >&copy; Deimos</a
      ></span
    >
    <span> - v{{ pkg.version }}</span>
    <div *ngIf="!env.production">
      <a class="absolute items-center justify-center flex gap-4 z-40 bg-danger-500 shadow-inner bottom-4 right-0 rounded-l-full py-1 px-2 hover:px-5 active:scale-y-90 duration-300"
         href="https://jira.elecnor-deimos.com/projects/SERV4EO?selectedItem=com.atlassian.jira.jira-projects-plugin%3Arelease-page&status=unreleased"
         target="_blank">
        <span class="text-gray-50">🛰 services4eo - v{{pkg["service4eo-version"]}}</span>
        <span class="text-gray-50">⚙️ application - v{{pkg["version"]}}</span>
      </a>
    </div>
  </div>
</div>
