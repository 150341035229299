import { Option } from './../../../../../../@core/models/input';
import { DomainArea } from './../../../../../../@core/models/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FilterService, ServicesService } from '@core';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'app-domain-area-filter',
  templateUrl: './domain-area-filter.component.html',
  styleUrls: ['./domain-area-filter.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px' })),
      transition('false <=> true', [animate(500)]),
    ]),
  ],
})
export class DomainAreaFilterComponent implements OnInit {
  domainAreas: DomainArea[];

  @Output() propagate = new EventEmitter();

  iconUp: IconDefinition = faChevronRight;
  iconDown: IconDefinition = faChevronDown;

  isLoading: boolean = true;

  domainAreasPublished$: Observable<any> =
    this.servicesService.getSelectableDomainAreasPublished();

  selectedCategories: string[] = [];

  constructor(
    private servicesService: ServicesService,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.servicesService
      .getSelectableDomainAreasPublished()
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          //TODO: remove sort the front to the backend
          this.domainAreas = data.sort((a, b) => a.name.localeCompare(b.name));
          this.isLoading = false;
        },
      });

    this.filterService.filterChange$.subscribe((data) => {
      if (data.type === 'clear') this.selectedCategories = [];
    });
  }

  onExpandOrCollapseRow(index: number) {
    this.domainAreas[index].expanded = !this.domainAreas[index].expanded;
  }

  onSelectCategory(event: Option) {
    this.filterService.onCategoryChange(!!event.checked, `${event.value}`);

    this.selectedCategories.push(event.value);

    this.propagate.emit(event);
  }
}
