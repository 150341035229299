import { environment } from '../../../environments';

const MIN_CREDITS = 20;
const MAX_CREDITS = 999;

const SPACECHAIN_API_KEY = environment.spaceChainApiKey;
const SPACECHAIN_WALLET_URL = environment.spaceChainWalletURL;
const WALLET_ADDRESS = environment.deimosWalletAddress;
const GAS_PRICE_URL = 'https://ethgasstation.info/json/ethgasAPI.json';

const EIP155 = {
  MAINNET: 1,
  MORDEN: 2,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  KOVAN: 42,
};

const fetchOptions = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-API-KEY': SPACECHAIN_API_KEY,
  },
};

export {
  MIN_CREDITS,
  MAX_CREDITS,
  EIP155,
  SPACECHAIN_WALLET_URL,
  WALLET_ADDRESS,
  GAS_PRICE_URL,
  fetchOptions,
};
