import { Component, Input, OnInit } from '@angular/core';
import {
  OrdersServices, PaymentPayload, SpaceChainService
} from '@core';
import { faSpacechain } from '../../../../../assets/icons';

@Component({
  selector: 'credits-checkout',
  templateUrl: './credits-checkout.component.html',
  styleUrls: ['./credits-checkout.component.scss'],
})
export class CreditsCheckoutComponent implements OnInit {
  constructor(
    private spaceChainService: SpaceChainService,
    private ordersService: OrdersServices
  ) {}

  spaceWallet = this.spaceChainService.wallet;
  faSpacechain = faSpacechain;

  @Input() amountToWithdraw?: string;
  @Input() serviceName?: string;
  @Input() orderId?: number;

  ngOnInit(): void {
    this.spaceChainService.$wallet.subscribe(
      (wallet) => (this.spaceWallet = wallet)
    );
  }

  isWalletPopupOpen: boolean = false;

  get estAmountToWithdraw() {
    if (this.amountToWithdraw)
      return parseFloat(this.amountToWithdraw).toPrecision(4);

    return '0';
  }

  onCreditsPay() {
    this.isWalletPopupOpen = true;
    if (this.amountToWithdraw) {
      const amount = parseFloat(this.amountToWithdraw);

      this.spaceChainService
        .signMessage(amount)
        .then((response) => {
          const payload: PaymentPayload = {
            reference: response.tx_id,
          };

          this.ordersService
            .generatePayment(payload, this.orderId)
            .subscribe(() => (this.isWalletPopupOpen = false));
        })
        .catch(() => (this.isWalletPopupOpen = false));
    }
  }

  get hasEnoughBalance() {
    if (this.spaceWallet?.balance && this.amountToWithdraw)
      return this.spaceWallet?.balance >= this.amountToWithdraw;

    return false;
  }

  get subtotal() {
    return this.estAmountToWithdraw;
  }

  get total() {
    return this.subtotal;
  }
}
