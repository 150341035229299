import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { httpOptions, INewOrder, 
  IOrderJob, IOrderPaginationResponse, 
  JobOutput, Order, OrderPermission, 
  PaymentPayload, ProductMetadata, 
  SnackbarService, 
  TranslateService} from '@core';
import { Observable, Subject } from "rxjs";
import { map, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersServices {
  ordersURL = `${environment.apiUrl}/v1/order`;
  userPermissionURL = `${environment.apiUrl}/v1/auth/validate-order`;
  productURL = `${environment.apiUrl}/v1/product`

  public orderMapToggled$: Subject<number> = new Subject();

  constructor(
    private httpService: HttpClient,
    private snackbarService: SnackbarService,
    private router: Router,
    private translate: TranslateService
  ) {}

  getAllUserOrders(unpaged: boolean, isSubscription: boolean, perPage?: number, page?: number) {
    if (isSubscription) {
      return this.httpService
      .get<IOrderPaginationResponse>(
        `${this.ordersURL}?unpaged=${unpaged}&perPage=${perPage}&page=${page}&subscription=${isSubscription}`,
        httpOptions
      ).pipe(take(1))
    } else {
      return this.httpService
      .get<IOrderPaginationResponse>(
        `${this.ordersURL}?unpaged=${unpaged}&perPage=${perPage}&page=${page}`,
        httpOptions
      ).pipe(take(1))
    }

  }

  createNewOrder(payload: INewOrder) {
    return this.httpService.post(this.ordersURL, payload, httpOptions);
  }

  generatePayment(payload: PaymentPayload, orderID?: number, isSubscription?: boolean) {
    const body = {
      paymentReference: payload.reference,
    };

    return this.httpService
      .post(`${this.ordersURL}/${orderID}/pay`, body, httpOptions)
      .pipe(
        map(() => {
          if (!isSubscription) {
            this.snackbarService
            .success(
              this.translate.translate('main-page.orders.snackbar.order.title'),
              this.translate.translate('main-page.orders.snackbar.order.message')
            )
            .during(5000)
            .show();
          } else {
            this.snackbarService.success(
              this.translate.translate('main-page.orders.snackbar.subscription.title'),
              this.translate.translate('main-page.orders.snackbar.subscription.message')
            ).during(5000)
            .show();
          }


          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/customers/orders']);
        })
      );
  }

  downloadOrderResult(productId: string) {
    const httpOptions = {
      responseType: 'text' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: 'text/plain;charset=UTF-8',
      }),
    };
    return this.httpService.get(
      environment.apiUrl + '/v1/product/' + productId + '/download',
      httpOptions
    );
  }

  downloadAllOrderResults(orderId: number): Observable<Blob> {
    return this.httpService.get(
      environment.apiUrl + '/v1/order/' + orderId + '/download-all',
      {
        responseType: 'blob',
        headers: new HttpHeaders({
          'Content-Type': 'text/plain;charset=UTF-8',
          Accept: 'text/plain;charset=UTF-8',
        })
      }
    );
  }

  getOrderJobs(orderId: number) {
    return this.httpService
      .get<IOrderJob[]>(
        `${this.ordersURL}/${orderId}/jobs`,
        httpOptions
      ).pipe(take(1))
  }

  getOrderOutputs(orderId?: number) {
    return this.httpService.get<JobOutput[]>(
      `${this.ordersURL}/${orderId}/outputs`, httpOptions
    ).pipe(take(1));
  }

  checkCommercialUserPermission(serviceIdentifier: string, serviceLicence: string): Observable<OrderPermission> {
    const payload = {
      serviceId: serviceIdentifier,
      type: serviceLicence
    };
    return this.httpService.post<OrderPermission>(`${this.userPermissionURL}`, payload, httpOptions);
  }

  getProductMetadata(productIdentifier: string): Observable<ProductMetadata> {
    const url = `${this.productURL}/${productIdentifier}`;
    return this.httpService.get<ProductMetadata>(url, httpOptions);
  }

  generateBulkPayment(orderIds: number[], paymentReference: string): Observable<any> {
    const payload = {
      orderIds: orderIds,
      paymentReference: paymentReference
    }

    return this.httpService.post(`${this.ordersURL}/pay-all`, payload, httpOptions);
  }

  downloadOutput(url: string): Observable<any> {
    return this.httpService.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: 'text/plain;charset=UTF-8',
      })
    });
  }
}
