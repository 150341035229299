import { Currency, CurrencyCode } from '@core';
import {
  fetchOptions,
  SPACECHAIN_WALLET_URL
} from '@core/configs/spacechain.config';
import { BigNumber, utils } from 'ethers';

const ETH_DECIMAL_UNIT = 18;

type Price = { [key: string]: number };

async function call(
  fromCurrencyCode: CurrencyCode,
  toCurrencyCode: CurrencyCode
): Promise<Price> {
  const currency_converter = new URL(`${SPACECHAIN_WALLET_URL}/currency`);

  currency_converter.search = new URLSearchParams({
    from_token: fromCurrencyCode,
    to_token: toCurrencyCode,
  }).toString();

  return fetch(currency_converter.toString(), { ...fetchOptions }).then(
    async (response) => response.json()
  );
}

const toBigNumber = (amount: string | number) => BigNumber.from(amount);

const ethtoCurrency = async (
  amount: number,
  currencyCode: Currency['code']
) => {
  return call(CurrencyCode.ETH, currencyCode).then(async (price) => {
    const ethAmount = amount / price[currencyCode];

    const bnAmount = utils.parseUnits(ethAmount.toString(), ETH_DECIMAL_UNIT);

    return { ethAmount, bnAmount };
  });
};

const currencyToEth = async (
  amount: number,
  currencyCode: Currency['code']
) => {
  const ethCurrencyCode = CurrencyCode.ETH;

  return call(currencyCode, ethCurrencyCode).then(async (price) => {
    const ethAmount = amount * price[ethCurrencyCode];

    const bnAmount = utils.parseUnits(ethAmount.toString(), ETH_DECIMAL_UNIT);

    return { ethAmount, bnAmount };
  });
};

export { toBigNumber, ethtoCurrency, currencyToEth };
