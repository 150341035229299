<div class="grid grid-cols-12">
  <header class="col-span-12">
    <app-navbar
      [lang]="langId"
      (currentLang)="setLang($event)"
      [scrollTop]="scrollTop"
    ></app-navbar>
    <spacechain-wallet *ngIf="hasMetaPlugin"></spacechain-wallet>
  </header>
  <main class="col-span-12 min-h-fit">
    <router-outlet></router-outlet>
  </main>
  <app-footer class="col-span-12"></app-footer>
</div>
