import { PaymentMethod, PaymentMethodSlug } from '@core/models';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faSpacechain } from '../../../assets/icons';

export const paymentMethods: PaymentMethod[] = [
  {
    slug: PaymentMethodSlug.PAYPAL,
    title: 'Paypal',
    icon: faPaypal,
  },
  {
    slug: PaymentMethodSlug.CREDITS,
    title: 'Credits',
    icon: faSpacechain,
  },
];
