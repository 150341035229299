export const environment = {
  production: true,
  staging: false,
  apiUrl: 'https://api.services4eo.com/request',
  authority: 'https://triple-a.services4eo.com',
  clientId: 'c1d64a5a-b11c-45b5-a9e6-170fbc7572b9',
  scope: 'profile openid email user_name gsc4eo',
  geoServerUrl: 'https://webgeo2.hidrografico.pt/geoserver/ows',
  apiKey: 'ae03ec27-71ac-45c3-817c-4a5334edcfec',
  paypal_clientID: 'ASdJ0ZIiMTct1KdvO5DCrFZeyCGu61wz5ZnzfczHnBvU37R1hTfMFJrF2SvEeonFJB2mDSbAGfSv1Qw0',
  spaceChainApiKey: 'SLAAlbxUWT2KQWsHYjVOQ5vede2FtYa1KPCw4Xie',
  spaceChainWalletURL: 'https://73vg5cyj9e.execute-api.eu-west-2.amazonaws.com/prod',
  deimosWalletAddress: '0x0CdA49DF14D116442c54D37cf7f08B855F289128',
  //? External links
  externalLinks: {
    deimos: 'https://elecnor-deimos.com',
    esa: 'https://www.esa.int',
    support: 'https://service4eo.atlassian.net/servicedesk',
    linkedin:'https://www.linkedin.com/company/store4eo/about/',
    twitter:'https://twitter.com/store4eo',
    requestAreaUrl:'https://service4eo.atlassian.net/servicedesk/customer/portal/9/group/44/create/133',
    youtube:''
  },

  cms: {
    url: 'https://cms4eo.services4eo.com',
    api: 'https://cms4eo.services4eo.com/api',
    website: 'st4eo',
  },
  downloadAllHelp:{
    url:'https://en.wikipedia.org/wiki/Comparison_of_download_managers'
  },
  geoportalUrl: 'https://geoportal.services4eo.com/home',
  outputFilterPattern: '',
  analyticsID: 'G-5RKDH27D0X',
  storageUrl: 'https://data-lake.services4eo.com'
};
