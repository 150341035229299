<ng-container *ngIf="order && service && !isLoading">
  <div
    class="flex flex-col md:flex-row md:justify-between flex-wrap border-b border-b-secondary-300 w-screen p-2 md:p-6"
  >
    <div class="flex flex-col flex-1 md:flex-row">
      <div class="flex w-fit md:w-80 items-start pt-4">
        <img
          (error)="errorHandler($event)"
          [src]="thumbnail"
          alt=""
          class="rounded-lg shadow"
        />
      </div>
      <div class="w-fit pt-4 md:p-4 md:ml-4">
        <div class="flex flex-row gap-2 pb-2">
          <div
          *ngIf="service.serviceCategory"
          class="lg:p-2 p-1 bg-primary-200 border-primary-400 border text-primary-600 w-fit rounded lg:rounded-lg"
          >
            <span class="text-sm md:text-base">{{ service.serviceCategory.name }}</span>
          </div>
          <div
            *ngIf="order.isSubscription"
            class="lg:p-2 p-1 bg-secondary-200 border-secondary-400 border text-secondary-600 w-fit rounded lg:rounded-lg"
          >
            <span class="text-sm md:text-base">{{ 'labels.subscription-tag' | translate }}</span>
          </div>
          <span *ngIf="order.isSubscription" class="text-sm md:text-base text-secondary-500 my-auto">
            {{ ('labels.timeStep.updated' | translate) + ' ' }} 
            {{ order.timeStep === 1 ? 
              ('labels.timeStep.day' | translate) : order.timeStep + ' ' + ('labels.timeStep.days' | translate) }}
          </span>
        </div>

        <div>
          <span class="text-primary-500 font-bold" *ngIf="hasNewOutputs">
            {{ 'labels.new-outputs' | translate }}
          </span>
          <h1
            class="text-secondary-500 font-bold md:text-lg lg:text-2xl mt-4 mb-4"
          >
            {{ service.title }}
          </h1>
          <div
            class="grid col-auto grid-cols-2 lg:grid-cols-2 gap-4"
            *ngIf="order"
          >
            <app-order-property-item
              *ngFor="let propertyItem of orderPropertyItems"
              [label]="propertyItem.label | translate"
              [value]="propertyItem.value"
              [blink]="propertyItem.blink"
            ></app-order-property-item>
            <div *ngIf="orderAOIGeoJSON && orderAOIGeoJSON !== null" class="flex justify-start pt-2">
              <a 
                (click)="toggleAOIMap()"
                class="align-self-end text-primary-500 underline cursor-pointer"
              >{{ ( !isShowingMap ? 'helper-texts.showMap' : 'helper-texts.hideMap') | translate}}</a>
            </div>
          </div>

          
        </div>
        <div class="flex justify-start py-2" *ngIf="isShowingMap">
          <div class="w-96 h-56 ml-1 mr-2">
            <app-map-thumbnail [aoiGeoJSON]="orderAOIGeoJSON" [orderID]="order.id" (mapDestroyed)="emitMapDestroyed()"></app-map-thumbnail>
          </div>
        </div>
        <div *ngIf="order.status === 'ERROR'" class="pt-4">
          <span class="text-gray-500">{{ 'main-page.orders.error-message' | translate }}</span>
        </div>
      </div>

    </div>

    <span [ngSwitch]="order.status" *ngIf="!order.isSubscription">
      <div *ngSwitchCase="OrderStatus.PAYMENT_PENDING">
        <h1 class="text-primary-500">
          {{ "main-page.orders.pending-payment" | translate }}
        </h1>
        <app-button
          [label]="'actions.pay-now' | translate"
          [isWhite]="true"
          [isFull]="true"
          class="w-full"
          (btnClick)="onPaymentReadyClick()"
          [isDisabled]="isPaymentReady || isProcessingPayment || isPriceLoading || disablePaymentButton"
          [leftIcon]="isProcessingPayment ? faSpinner : undefined"
          [iconClasses]="{ 'animate-spin': isProcessingPayment }"
        ></app-button>
        <div *ngIf="payment" class="flex flex-col gap-1 w-[26rem]">
          <div class="flex flex-row gap-1 justify-between">
            <span>{{ "main-page.orders.base" | translate }}: </span>
            <span>{{ payment.basePrice | currency: "EUR " : "code" }}</span>
          </div>
          <div class="flex flex-row gap-1 justify-between">
            <span>{{ "main-page.orders.vat" | translate }} ({{ payment.vat?.rate }}%): </span>
            <span>{{ payment.vat?.amount | currency: "EUR " : "code" }}</span>
          </div>
          <div class="flex flex-row gap-1 justify-between border-t-2 border-dashed">
            <span><b>{{ "main-page.orders.total" | translate }}: </b></span>
            <span>{{ payment.totalPrice | currency: "EUR " : "code"}}</span>
          </div>
          <div
            *ngIf="payment && (userData$ | async)?.beta"
            class="flex flex-row flex-wrap justify-between lg:p-2 p-1 bg-green-200 border-green-400 border text-green-600 w-fit rounded lg:rounded-lg"
          >
            <fa-icon [icon]="faSmile" [size]="'2x'" class="my-auto"></fa-icon>
            <span class="w-5/6">{{ "main-page.orders.beta-disclaimer" | translate }}</span>
          </div>
        </div>
        <div *ngIf="!payment && isPriceLoading" class="flex flex-row justify-center mt-4">
          <fa-icon [icon]="faSpinner" size="2x" [ngClass]="{'animate-spin': true}"></fa-icon>
        </div>

        <app-dialog
          *ngIf="isPaymentReady"
          (cancel)="onDialogCancel()"
          size="sm"
        >
          <div class="flex flex-col gap-3">
            <h1 class="text-secondary-500">
              {{ "helper-texts.preferred-payment-method" | translate }}
            </h1>
            <div class="flex flex-wrap flex-shrink-0 gap-4">
              <p
                *ngIf="isPriceLoading && !payment; else priceLoaded"
                class="place-self-center text-gray-500"
              >
                {{ "actions.loading.page-content" | translate }}
              </p>
              <ng-template #priceLoaded>
                <app-button
                  *ngFor="let payment of paymentMethods"
                  (btnClick)="onPaymentClick(payment.slug)"
                  [isWhite]="true"
                  [label]="payment.title"
                  [title]="payment.title"
                  [leftIcon]="payment.icon"
                  [isSecondary]="selectedPayment === payment.slug"
                ></app-button>
              </ng-template>
            </div>

            <span [ngSwitch]="selectedPayment" *ngIf="payment">
              <div *ngSwitchCase="'PAYPAL'">
                <paypal-checkout (onApprove)="paypalApprove($event, false)" [price]="payment.totalPrice.toString()" (onClientAuthorization)="onDialogCancel()"></paypal-checkout>
              </div>
              <div *ngSwitchCase="'CREDITS'">
                <credits-checkout
                  [orderId]="order.id"
                  [serviceName]="service.title"
                  [amountToWithdraw]="payment.totalPrice.toString()"
                ></credits-checkout>
              </div>
            </span>
          </div>
        </app-dialog>
      </div>
      <ng-container *ngSwitchCase="OrderStatus.PAYMENT_PROCESSING">
        <div
          class="flex justify-center items-center md:justify-end md:items-end p-4"
        >
          <h1 class="text-blue-500">
            {{ "main-page.orders.processing-payment" | translate }}
          </h1>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="OrderStatus.CREATED">
        <div
          class="flex justify-center items-center md:justify-end md:items-end p-4"
        >
          <h1 class="text-blue-500">
            {{ "main-page.orders.created" | translate }}
          </h1>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div
          class="flex justify-center items-center md:justify-end md:items-end p-4"
        >
          <app-button
            (btnClick)="isShowDetails = !isShowDetails"
            [isDark]="isShowDetails"
            [isSecondary]="true"
            [label]="'button-labels.details-download' | translate"
            [rightIcon]="isShowDetails ? chevronUp : chevronDown"
            [isDisabled]="order.status === 'ERROR'"
          ></app-button>
        </div>
      </ng-container>
    </span>
    <span *ngIf="order.isSubscription" [ngSwitch]="order.status">
      <ng-container *ngSwitchCase="OrderStatus.SUBSCRIPTION_ACTIVE">
        <div
          class="flex justify-center items-center md:justify-end md:items-end p-4"
          style="align-items: start;"
        >
          <span class="relative flex h-4 w-4 bottom-[5px] left-[10px] z-50" *ngIf="hasNewOutputs">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-4 w-4 bg-primary-500"></span>
          </span>
          <app-button
            (btnClick)="toggleOutputDisplay()"
            [isDark]="isShowDetails"
            [isSecondary]="true"
            [label]="'button-labels.details-download' | translate"
            [rightIcon]="isShowDetails ? chevronUp : chevronDown"
            [isDisabled]="order.status === 'ERROR'"
          ></app-button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="OrderStatus.FINISHED">
        <div
          class="flex justify-center items-center md:justify-end md:items-end p-4"
          style="align-items: start;"
        >
          <span class="relative flex h-4 w-4 bottom-[5px] left-[10px] z-50" *ngIf="hasNewOutputs">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-4 w-4 bg-primary-500"></span>
          </span>
          <app-button
            (btnClick)="toggleOutputDisplay()"
            [isDark]="isShowDetails"
            [isSecondary]="true"
            [label]="'button-labels.details-download' | translate"
            [rightIcon]="isShowDetails ? chevronUp : chevronDown"
            [isDisabled]="order.status === 'ERROR'"
          ></app-button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="OrderStatus.PAYMENT_PENDING">
          <h1 class="text-primary-500">
            {{ "main-page.orders.pending-payment" | translate }}
          </h1>
          <app-button
            [label]="'actions.pay-now' | translate"
            [isWhite]="true"
            [isFull]="true"
            class="w-full"
            (btnClick)="onPaymentReadyClick()"
            [isDisabled]="isPaymentReady || isProcessingPayment || isPriceLoading || disablePaymentButton"
            [leftIcon]="isProcessingPayment ? faSpinner : undefined"
            [iconClasses]="{ 'animate-spin': isProcessingPayment }"
          ></app-button>
          <div *ngIf="payment" class="flex flex-col gap-1 w-[26rem]">
            <div class="flex flex-row gap-1 justify-between">
              <span>{{ "main-page.orders.base" | translate }}: </span>
              <span>{{ payment.basePrice | currency: "EUR " : "code" }}</span>
            </div>
            <div class="flex flex-row gap-1 justify-between">
              <span>{{ "main-page.orders.vat" | translate }} ({{ payment.vat?.rate }}%): </span>
              <span>{{ payment.vat?.amount | currency: "EUR " : "code" }}</span>
            </div>
            <div class="flex flex-row gap-1 justify-between border-t-2 border-dashed">
              <span><b>{{ "main-page.orders.total" | translate }}: </b></span>
              <span>{{ payment.totalPrice | currency: "EUR " : "code"}}</span>
            </div>
            <div
              *ngIf="payment && (userData$ | async)?.beta"
              class="flex flex-row flex-wrap justify-between lg:p-2 p-1 bg-green-200 border-green-400 border text-green-600 w-fit rounded lg:rounded-lg"
            >
              <fa-icon [icon]="faSmile" [size]="'2x'" class="my-auto"></fa-icon>
              <span class="w-5/6">{{ "main-page.orders.beta-disclaimer" | translate }}</span>
            </div>
          </div>
          <div *ngIf="!payment && isPriceLoading" class="flex flex-row justify-center mt-4">
            <fa-icon [icon]="faSpinner" size="2x" [ngClass]="{'animate-spin': true}"></fa-icon>
          </div>
  
          <app-dialog
            *ngIf="isPaymentReady"
            (cancel)="onDialogCancel()"
            size="sm"
          >
            <div class="flex flex-col gap-3">
              <h1 class="text-secondary-500">
                {{ "helper-texts.preferred-payment-method" | translate }}
              </h1>
              <div class="flex flex-wrap flex-shrink-0 gap-4">
                <p
                  *ngIf="isPriceLoading && !payment; else priceLoaded"
                  class="place-self-center text-gray-500"
                >
                  {{ "actions.loading.page-content" | translate }}
                </p>
                <ng-template #priceLoaded>
                  <app-button
                    *ngFor="let payment of paymentMethods"
                    (btnClick)="onPaymentClick(payment.slug)"
                    [isWhite]="true"
                    [label]="payment.title"
                    [title]="payment.title"
                    [leftIcon]="payment.icon"
                    [isSecondary]="selectedPayment === payment.slug"
                  ></app-button>
                </ng-template>
              </div>
  
              <span [ngSwitch]="selectedPayment" *ngIf="payment">
                <div *ngSwitchCase="'PAYPAL'">
                  <paypal-checkout (onApprove)="paypalApprove($event, true)" [price]="payment.totalPrice.toString()" (onClientAuthorization)="onDialogCancel()"></paypal-checkout>
                </div>
                <div *ngSwitchCase="'CREDITS'">
                  <credits-checkout
                    [orderId]="order.id"
                    [serviceName]="service.title"
                    [amountToWithdraw]="payment.totalPrice.toString()"
                  ></credits-checkout>
                </div>
              </span>
            </div>
          </app-dialog>
      </ng-container>
      <ng-container *ngSwitchCase="OrderStatus.PAYMENT_PROCESSING">
        <div
          class="flex justify-center items-center md:justify-end md:items-end p-4"
        >
          <h1 class="text-blue-500">
            {{ "main-page.orders.processing-payment" | translate }}
          </h1>
        </div>
      </ng-container>
    </span>

  </div>

  <app-order-item-details
    *ngIf="isShowDetails"
    [@Details]=""
    [service]="service"
    [inputs]="order.inputs"
    [orderId]="order.id"
    [orderStatus]="order.status"
    [isMetadataLoading]="isMetadataLoading"
    (viewMetadata)="viewMetadataClicked($event)"
    [isSubscription]="order.isSubscription"
  >
  </app-order-item-details>
</ng-container>

<app-dialog
  size="w-4/12"
  *ngIf="isMetadataDialogOpen"
  (cancel)="onMetadataClose()"
>
  <div class="flex flex-col gap-4">
    <span class="text-center font-bold text-lg pb-4">{{ 'labels.product-metadata.menu-title' | translate}}</span>
    <div class="flex flex-row gap-4 justify-between">
      <p class="font-bold">{{ 'labels.product-metadata.name' | translate}}</p>
      <p>{{ metadataInformation.name }}</p>
    </div>
    <div class="flex flex-row gap-4 justify-between">
      <p class="font-bold">{{ 'labels.product-metadata.description' | translate}}</p>
      <p>{{ metadataInformation.description }}</p>
    </div>
    <div class="flex flex-row gap-4 justify-between">
      <p class="font-bold">{{ 'labels.product-metadata.acquisition' | translate}}</p>
      <p>{{ metadataInformation.metadata?.downlinkedTo?.information?.acquiredAt }}</p>
    </div>
    <div class="flex flex-row gap-4 justify-between">
      <p class="font-bold">{{ 'labels.product-metadata.processing' | translate}}</p>
      <p>{{ metadataInformation.metadata?.processing?.information?.processedAt }}</p>
    </div>
    <div class="flex flex-row gap-4 justify-between">
      <p class="font-bold">{{ 'labels.product-metadata.provider' | translate}}</p>
      <p>{{ metadataInformation.extraFields?.provider }}</p>
    </div>
    <div class="flex flex-row gap-4 justify-between">
      <p class="font-bold">{{ 'labels.product-metadata.crs' | translate}}</p>
      <p>{{ metadataInformation.extraFields?.crs }}</p>
    </div>
    <div class="flex flex-row gap-4 justify-between">
      <p class="font-bold">{{ 'labels.product-metadata.datatype' | translate}}</p>
      <p>{{ metadataInformation.extraFields?.dataType }}</p>
    </div>
    <div class="flex flex-row gap-4 justify-between">
      <p class="font-bold">{{ 'labels.product-metadata.variabletype' | translate}}</p>
      <p>{{ metadataInformation.extraFields?.variableType }}</p>
    </div>
    <div class="flex flex-row gap-4 justify-between">
      <p class="font-bold">{{ 'labels.product-metadata.footprint' | translate}}</p>
      <p class="w-1/2 break-all">{{ metadataInformation.footprint }}</p>
    </div>
  </div>
</app-dialog>

