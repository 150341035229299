<div
  class="bg-gray-100 border border-gray-300 p-3 rounded-md flex flex-col gap-3"
  *ngIf="spaceWallet; else noWalletConnected"
>
  <h1 class="text-secondary-500">
    <span class="font-bold">{{ "labels.address" | translate }}</span>
    {{ spaceWallet.address }}
  </h1>
  <h1 class="text-primary-500">
    <span class="font-bold">{{ "labels.balance" | translate }}</span>
    <span [title]="spaceWallet.balance" class="lowercase">
      {{ spaceWallet.estBalance }} {{ "labels.credits" | translate }}
    </span>
  </h1>

  <hr class="border border-secondary-400 w-full" />

  <div class="flex justify-between text-secondary-500">
    <h1 class="text-sm">{{ serviceName }}</h1>
    <h1 class="font-bold lowercase">
      {{ estAmountToWithdraw }} {{ "labels.credits" | translate }}
    </h1>
  </div>

  <hr class="border-dashed border-secondary-400 w-full" />

  <div class="flex justify-between text-secondary-500">
    <h1 class="text-sm uppercase">{{ "labels.subtotal" | translate }}</h1>
    <h1 class="lowercase">{{ subtotal }} {{ "labels.credits" | translate }}</h1>
  </div>

  <div class="flex justify-between text-secondary-500 font-bold text-md">
    <h1 class="uppercase">{{ "labels.total" | translate }}</h1>
    <h1 class="lowercase">{{ total }} {{ "labels.credits" | translate }}</h1>
  </div>
  <app-button
    (btnClick)="onCreditsPay()"
    [leftIcon]="faSpacechain"
    [label]="
      hasEnoughBalance
        ? ('actions.pay-credits' | translate)
        : ('errors.insufficient-credits' | translate)
    "
    [isSecondary]="true"
    [isDisabled]="!hasEnoughBalance || isWalletPopupOpen"
    [isFull]="true"
  ></app-button>
</div>
<ng-template #noWalletConnected>
  <h1>{{ "errors.no-wallet-connected" | translate }}</h1>
  <a
    class="hover:cursor-pointer hover:text-primary-500 duration-300 hover:underline font-bold text-secondary-500"
  >
  </a>
</ng-template>
