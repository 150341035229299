import { Validators } from '@angular/forms';
import { MAX_CREDITS, MIN_CREDITS } from '../spacechain.config';

export const ethereumPaymentFormConfig = {
  amountInEUR: [
    null,
    [
      Validators.required,
      Validators.min(MIN_CREDITS),
      Validators.max(MAX_CREDITS),
    ],
  ],
};
