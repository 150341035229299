import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

const prefix = 'fas';
const iconName = 'worm';
const width = 546;
const height = 512;
const aliases: string[] = [];
const unicode = 'f92sc';
const svgPathData =
  'M491.099 9.06458C470.647 20.5611 476.266 17.353 450.642 28.1087C426.696 38.1644 399.552 35.7243 358.182 19.8093C220.225 -33.267 68.21 53.5778 28.0028 193.097C12.1572 248.09 27.5181 366.675 49.3916 358.241C52.7015 356.97 67.4141 348.069 75.7253 342.839C83.6043 337.887 90.5964 333.55 91.2615 333.208C91.9267 332.861 89.2196 315.439 85.244 294.483C73.1007 230.44 86.1103 180.254 128.723 126.759C182.382 59.4048 283.667 47.0483 357.347 85.8213L344.874 93.6234C332.978 101.063 275.959 133.556 217.624 166.142C209.437 170.714 179.261 190.535 177.487 197.636C173.279 214.468 182.53 231.331 198.123 235.263C210.591 238.407 219.965 234.035 298.884 188.259C317.318 177.566 342.842 162.822 355.604 155.491C368.366 148.16 393.215 133.877 410.819 123.749C428.428 113.621 460.913 95.1465 483.008 82.6922C547.53 46.3239 557.06 33.3101 535.877 10.4737C523.306 -3.08408 513.287 -3.40009 491.099 9.06458ZM473.954 168.888L450.508 183.047L457.706 214.131C466.539 252.24 460.506 302.663 442.691 339.684C426.525 373.286 387.362 411.343 351.665 428.154C300.872 452.072 239.219 453.371 190.269 426.077L221.156 408.623C249.954 392.347 268.226 381.051 299.724 362.723C362.256 326.349 365.742 323.878 368.98 313.62C372.605 302.15 359.374 280.79 346.013 276.527C339.099 274.325 288.52 300.731 177.002 364.764C89.4774 415.021 13.8021 459.751 8.83654 464.16C-0.532638 472.485 -2.87877 489.39 3.8658 500.036C16.9031 520.651 45.8836 510.63 62.1176 499.684C102.337 472.563 126.526 470.335 180.483 488.773C339.986 543.281 521.21 433.45 521.821 258.166C521.97 216.716 509.383 154.429 500.906 154.641C498.983 154.688 486.855 161.102 473.954 168.888Z';

export const faSpacechain: IconDefinition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};
