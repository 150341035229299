export enum WalletState {
  CONNECTED = 'connected',
  NOT_CONNECTED = 'not_connected',
}

export interface EthereumEventChange {
  payload: string[];
  isEmpty: boolean;
}

export interface Withdraw {
  msg: string;
  signature: string | void;
  address: string;
  amount: number;
}
